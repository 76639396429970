<template>
  <v-container class="pa-0 align-start fill-height ma-0" fluid>
    <transition name="zoom" mode="out-in">
    <router-view />
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "InvalidBooking",
  data: () => ({}),
  methods:{
  }
};
</script>
<style lang="less">
</style>
